import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableWithHeadingAndSearch from '../components/Table/TableWithHeadingAndSearch';
import {
    fetchPurchaseOrders,
    getPurchaseOrders,
} from '../app/reducers/PurchaseOrder/purchaseOrderSlice';
import moment from 'moment';
import {
    fetchComplaints,
    getComplaints,
} from '../app/reducers/Complaints/complaintSlice';

const Dashboard = () => {
    const { purchaseOrder, loading: poLoading } =
        useSelector(getPurchaseOrders);
    const { complaint, loading: compLoading } = useSelector(getComplaints);
    console.log('comp.s', complaint);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchPurchaseOrders({
                populate: true,
                createdAt: {
                    $gte: moment().startOf('day').valueOf(),
                    $lte: moment().endOf('day').valueOf(),
                },
            })
        );
        dispatch(
            fetchComplaints({
                populate: true,
                createdAt: {
                    $gte: moment().startOf('day').valueOf(),
                    $lte: moment().endOf('day').valueOf(),
                },
            })
        );
    }, []);

    const poCol = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + purchaseOrder?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'ORD_Number',
            accessor: 'orderNumber',
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Product',
            accessor: 'product.name',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Cyan',
            accessor: 'cyan',
        },
        {
            Header: 'Magenta',
            accessor: 'magenta',
        },
        {
            Header: 'Black',
            accessor: 'black',
        },
        {
            Header: 'Yellow',
            accessor: 'yellow',
        },
        {
            Header: 'Cleaning Solution',
            accessor: 'cleaningSolution',
        },
        {
            Header: 'Customer Message',
            accessor: 'customerMessage',
        },
    ];

    const compCol = [
        {
            Header: 'SRNO',
            Cell: (prop) => {
                const { data, row } = prop;
                return row.index + complaint?.pagingCounter;
            },
        },
        {
            Header: 'Date',
            Cell: (prop) => {
                const { data, row } = prop;
                return moment(row?.original?.createdAt).format(
                    'DD/MM/YYYY HH:mm'
                );
            },
        },
        {
            Header: 'Photos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.photos?.map &&
                            row.original.photos.map((ele) => (
                                <a
                                    href={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    target="_blank"
                                >
                                    <img
                                        src={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    />
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Videos',
            Cell: ({ row }) => {
                return (
                    <div className="flex flex-col gap-2">
                        {row.original?.videos?.map &&
                            row.original.videos.map((ele, i) => (
                                <a
                                    className="text-indigo-500 underline"
                                    href={`${process.env.REACT_APP_S3_URL}${ele}`}
                                    target="_blank"
                                >
                                    <p>Video {i + 1}</p>
                                </a>
                            ))}
                    </div>
                );
            },
        },
        {
            Header: 'Customer',
            accessor: 'customer.name',
        },
        {
            Header: 'Company',
            accessor: 'customer.companyData.name',
        },
        {
            Header: 'Machine',
            accessor: 'product.name',
        },
        {
            Header: 'Description',
            Cell: ({ row }) => {
                return (
                    <div className="w-[300px]">
                        <p className="text-wrap ">
                            {row?.original?.description}
                        </p>
                    </div>
                );
            },
        },
    ];

    const poData = useMemo(() => {
        if (purchaseOrder && purchaseOrder?.docs) return purchaseOrder?.docs;
        else return [];
    }, [purchaseOrder]);

    const compData = useMemo(() => {
        if (complaint && complaint?.docs) return complaint?.docs;
        else return [];
    }, [complaint]);

    return (
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* Page header */}
            <div className="mb-8">
                {/* Title */}
                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
                    Dashboard
                </h1>
            </div>
            <div className="h-[355px] overflow-auto">
                <TableWithHeadingAndSearch
                    columns={poCol}
                    data={poData}
                    heading={"Today's P.O"}
                />
            </div>
            <div className="mt-8 h-[355px] overflow-auto">
                <TableWithHeadingAndSearch
                    columns={compCol}
                    data={compData}
                    heading={"Today's Complaints"}
                />
            </div>

            {/* Content */}
            <div className="bg-white shadow-lg rounded-sm mb-8">
                <div className="flex flex-col md:flex-row md:-mr-px"></div>
            </div>
        </div>
    );
};

export default Dashboard;
