import { useState } from 'react';
import { useEffect } from 'react';
import { Folder } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import {
    fetchAssets,
    fetchAssetsRaw,
    getMediaLibrary,
    openListAssetsModal,
    selectAsset,
} from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import ModalBasic from '../../components/infrastructure/Modals/ModalBasic';
import { getAuth } from '../../app/reducers/Auth/authSlice';

const MediaLibraryAssets = () => {
    const [currentFolder, setCurrentFolder] = useState('/');
    const { user } = useSelector(getAuth);
    const {
        listAssetsModalOpen,
        folders,
        assets,
        loading,

        selectedAssets,
    } = useSelector(getMediaLibrary);
    const dispatch = useDispatch();
    const setModalState = (state) => {
        dispatch(openListAssetsModal(state));
    };
    useEffect(() => {
        if (user?.role) {
            dispatch(fetchAssets({ currentFolder }));
        }
    }, [currentFolder, user]);
    return (
        <ModalBasic
            ignoreClick={true}
            modalOpen={listAssetsModalOpen}
            setModalOpen={setModalState}
            title="Select Assets"
        >
            <div className="flex flex-col gap-2 p-4">
                <input
                    type="text"
                    placeholder="search.."
                    onChange={(e) => {
                        dispatch(fetchAssetsRaw({ search: e.target.value }));
                    }}
                />
                {loading ? (
                    <ClipLoader />
                ) : (
                    <>
                        <div className="flex flex-col gap-4">
                            <div>
                                Folders
                                <div className="grid grid-cols-4 mt-8">
                                    <div className="flex items-center">
                                        <PrimaryButton
                                            onClick={() => {
                                                const pathArray = currentFolder
                                                    .split('/')
                                                    .filter((ele) => ele);
                                                const backArray =
                                                    pathArray.slice(
                                                        0,
                                                        pathArray.length - 1
                                                    );
                                                const newPath = `${backArray}/`;

                                                if (pathArray.length !== 0)
                                                    setCurrentFolder(newPath);
                                            }}
                                            readOnly={
                                                currentFolder
                                                    .split('/')
                                                    .filter((ele) => ele)
                                                    .length === 0
                                            }
                                        >
                                            Back
                                        </PrimaryButton>
                                    </div>
                                    {folders.map((folderName, i) => (
                                        <div
                                            key={i}
                                            className="flex flex-col items-center cursor-pointer"
                                            onClick={() => {
                                                if (currentFolder === '/') {
                                                    setCurrentFolder(
                                                        `${folderName}/`
                                                    );
                                                } else {
                                                    setCurrentFolder(
                                                        `${currentFolder}${folderName}/`
                                                    );
                                                }
                                            }}
                                        >
                                            <Folder size={70} />
                                            <p className="truncate">
                                                {folderName.replace(
                                                    currentFolder,
                                                    ''
                                                )}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div>
                                Assets
                                <div className="grid grid-cols-4 gap-4">
                                    {assets.map((asset) => {
                                        return (
                                            <div key={asset.Key}>
                                                <input
                                                    type="checkbox"
                                                    checked={
                                                        selectedAssets.includes[
                                                            asset.Key
                                                        ]
                                                    }
                                                    onChange={(e) => {
                                                        console.log(
                                                            e.target.checked
                                                        );
                                                        dispatch(
                                                            selectAsset(
                                                                asset.Key
                                                            )
                                                        );
                                                    }}
                                                />
                                                <img
                                                    src={`${process.env.REACT_APP_S3_URL}${asset.Key}`}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div>
                    <PrimaryButton
                        type="button"
                        onClick={() => {
                            dispatch(openListAssetsModal(false));
                        }}
                    >
                        Select
                    </PrimaryButton>
                </div>
            </div>
        </ModalBasic>
    );
};

export default MediaLibraryAssets;
