import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import { ClipLoader } from 'react-spinners';
import {
    editCompany,
    fetchCompany,
    getCompanies,
} from '../../app/reducers/Company/companySlice';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';
import { fetchEmployees, getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import { editCustomers, fetchCustomers, getCustomers } from '../../app/reducers/Customers/customerSlice';

const EditCompany = () => {
    const { id } = useParams();
    const { elementEditData } = useSelector(getCompanies);
    const { employees, loading } = useSelector(getEmployees);
    const { customer } = useSelector(getCustomers);
    console.log('custo"s>>>>', customer)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchCompany({ _id: id }));
        dispatch(fetchCustomers({company : id}))
        dispatch(fetchEmployees({role : 'executive'}))
    }, [id]);
    const navigate = useNavigate();
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            ...elementEditData,
        },
        onSubmit: async (values) => {
            await dispatch(editCompany(values));

            if(values?.executive?.length){
                customer?.docs?.forEach(async(ele)=>{
                   await dispatch(editCustomers({
                        ...ele,
                        executive : values?.executive,
                    }))
                })
            }

            await navigate('/master/company');
        },
    });

    return (
        <PageWithCard heading="Edit Company">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-3"
            >
                <FormikInputGroup
                    formik={formik}
                    name="name"
                    label="Name"
                    required
                />
                {/* machine */}

                <FormikProvider value={formik}>
                    <FieldArray
                        name="machine"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2 border p-2 rounded-md">
                                    <label className="text text-base font-semibold">
                                        Add Machine
                                    </label>
                                    <div>
                                        {formik?.values?.machine?.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <FormikAsyncSelect
                                                        label="Search Machine"
                                                        formik={formik}
                                                        name={`machine.${index}`}
                                                        getOptions={async (
                                                            value
                                                        ) => {
                                                            try {
                                                                const string =
                                                                    QueryString.stringify(
                                                                        {
                                                                            search: value,
                                                                            type: {
                                                                                $ne: 'INK',
                                                                            },
                                                                        }
                                                                    );

                                                                const productsResp =
                                                                    await authAxiosInstance.get(
                                                                        `/products?${string}`
                                                                    );
                                                                const options =
                                                                    productsResp?.data?.data?.docs?.map(
                                                                        (
                                                                            ele
                                                                        ) => ({
                                                                            label: ele?.name,
                                                                            value: ele?._id,
                                                                        })
                                                                    );
                                                                return options;
                                                            } catch (error) {
                                                                console.log(
                                                                    error
                                                                );
                                                            }
                                                        }}
                                                        onChange={(
                                                            selectedValue
                                                        ) => {
                                                            //check whether same id is used or not
                                                            const isSameMachineExist =
                                                                formik?.values?.machine?.find(
                                                                    (el) =>
                                                                        el ===
                                                                        selectedValue.value
                                                                );
                                                            if (
                                                                isSameMachineExist
                                                            ) {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                                return toast.error(
                                                                    'Already Selected above'
                                                                );
                                                            }
                                                            //machineId
                                                            formik.setFieldValue(
                                                                `machine.${index}`,
                                                                selectedValue.value
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-6 cursor-pointer">
                                                        <DangerButton
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <SecondaryButton
                                            onClick={() => {
                                                arrayHelpers.push({});
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </SecondaryButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </FormikProvider>

                {/* ink */}

                <FormikProvider value={formik}>
                    <FieldArray
                        name="ink"
                        render={(arrayHelpers) => {
                            return (
                                <div className="flex flex-col gap-2 border p-2 rounded-md">
                                    <label className="text text-base font-semibold ">
                                        Add Ink
                                    </label>
                                    <div>
                                        {formik?.values?.ink?.map(
                                            (ele, index) => (
                                                <div
                                                    className="relative p-4 mb-2"
                                                    style={{
                                                        border: '1px solid #d6c7c7',
                                                        borderRadius: '5px',
                                                    }}
                                                    key={index}
                                                >
                                                    <FormikAsyncSelect
                                                        label="Search Ink"
                                                        formik={formik}
                                                        name={`ink.${index}`}
                                                        getOptions={async (
                                                            value
                                                        ) => {
                                                            try {
                                                                const string =
                                                                    QueryString.stringify(
                                                                        {
                                                                            search: value,
                                                                            type: {
                                                                                $ne: 'MACHINE',
                                                                            },
                                                                        }
                                                                    );

                                                                const productsResp =
                                                                    await authAxiosInstance.get(
                                                                        `/products?${string}`
                                                                    );
                                                                const options =
                                                                    productsResp?.data?.data?.docs?.map(
                                                                        (
                                                                            ele
                                                                        ) => ({
                                                                            label: ele?.name,
                                                                            value: ele?._id,
                                                                        })
                                                                    );
                                                                return options;
                                                            } catch (error) {
                                                                console.log(
                                                                    error
                                                                );
                                                            }
                                                        }}
                                                        onChange={(
                                                            selectedValue
                                                        ) => {
                                                            //check whether same id is used or not
                                                            const isSameInkExist =
                                                                formik?.values?.ink?.find(
                                                                    (el) =>
                                                                        el ===
                                                                        selectedValue.value
                                                                );
                                                            if (
                                                                isSameInkExist
                                                            ) {
                                                                arrayHelpers.remove(
                                                                    index
                                                                );
                                                                return toast.error(
                                                                    'Already Selected above'
                                                                );
                                                            }
                                                            //Ink Id
                                                            formik.setFieldValue(
                                                                `ink.${index}`,
                                                                selectedValue.value
                                                            );
                                                        }}
                                                    />
                                                    <div className="mt-6 cursor-pointer">
                                                        <DangerButton
                                                            type="button"
                                                            onClick={() =>
                                                                arrayHelpers.remove(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </DangerButton>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div>
                                        <SecondaryButton
                                            onClick={() => {
                                                arrayHelpers.push({});
                                            }}
                                            type="button"
                                        >
                                            Add More
                                        </SecondaryButton>
                                    </div>
                                </div>
                            );
                        }}
                    />
                </FormikProvider>

                { loading ? (
                    <ClipLoader />
                )
                    :<FormikMultiSelect
                        formik={formik}
                        name="executive"
                        label="Executive"
                        options={ employees?.docs ?
                            employees?.docs?.map((ele)=>({
                                label : `${ele.firstName} ${ele.lastName}`,
                                value : ele._id
                            })) : []
                        }
                        
                    />}

                <div>
                    {formik.isSubmitting ? (
                        <ClipLoader />
                    ) : (
                        <PrimaryButton type="submit">Submit</PrimaryButton>
                    )}
                </div>
            </form>
        </PageWithCard>
    );
};
export default EditCompany;
