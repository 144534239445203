import { File } from 'react-feather';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import _ from 'lodash';
import { deleteObject } from '../../app/reducers/MediaLibrary/mediaLibrarySlice';
import { useDispatch } from 'react-redux';
import { checkURL } from '../../utils/Utils';
import { toast } from 'react-toastify';

function MediaLibraryItem({ asset, del = true }) {
    const folder = location.pathname.replace('/mediaLibrary', '')
        ? `${location.pathname.replace('/mediaLibrary/', '')}/`
        : '/';
    const decodedFolder = window.decodeURI(folder);
    const url = `${process.env.REACT_APP_S3_URL}${asset.Key}`;
    const dispatch = useDispatch();
    return (
        <div
            onClick={() => {
                navigator.clipboard.writeText(url);
                toast.info('url copied');
            }}
            className="bg-white shadow-lg rounded-sm border border-slate-200 max-h-[50vh]"
        >
            <div className="flex w-full flex-col items-center gap-2 h-full">
                <div className="p-4 w-full h-full justify-center flex max-h-[30vh]">
                    {checkURL(url) ? (
                        <img src={url} className="w-full object-contain " />
                    ) : (
                        <File size={100} />
                    )}
                </div>
                <div className="bg-slate-300 w-full h-full max-h-[20vh] items-start justify-center flex-col flex">
                    <p className="w-3/4 truncate text-black p-4">
                        {_.last(asset.Key.split('/'))}
                    </p>
                    {del ? (
                        <div className="flex items-center gap-2 p-2">
                            <DangerButton
                                onClick={() => {
                                    dispatch(
                                        deleteObject({
                                            keys: [asset.Key],
                                            currentFolder: decodedFolder,
                                        })
                                    );
                                }}
                            >
                                Delete
                            </DangerButton>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </div>
    );
}

export default MediaLibraryItem;
