import AddComplaint from '../pages/Complaints/AddComplaint';
import ViewComplaint from '../pages/Complaints/ViewComplaint';
import Dashboard from '../pages/Dashboard';
import AddDispatchLocation from '../pages/DispatchLocation/AddDispatchLocation';
import ViewDispatchLocation from '../pages/DispatchLocation/ViewLocation';
import Company from '../pages/Master/Company';
import EditCompany from '../pages/Master/EditCompany';
import MediaLibrary from '../pages/MediaLibrary/MediaLibrary';
import AddProduct from '../pages/Products/AddProduct';
import CreatePurchaseOrder from '../pages/PurchaseOrder/CreatePurchaseOrder';
import ViewPurchaseOrder from '../pages/PurchaseOrder/ViewPurchaseOrder';
import AddCustomer from '../pages/Users/AddCustomer';
import AddEmployee from '../pages/Users/AddEmployee';
import EditCustomer from '../pages/Users/EditCustomer';
import EditEmployee from '../pages/Users/EditEmployee';
import EditEmployeePage from '../pages/Users/EditEmployeePage';
import ViewCustomer from '../pages/Users/ViewCustomer';
import ViewEmployees from '../pages/Users/ViewEmployees';
import GeneralBulkUpload from '../pages/generalBulkUpload/GeneralBulkUpload';

export const routes = [
    {
        route: '/',
        element: <Dashboard />,
        allowedRoles: ['admin', 'manager','executive'],
    },
    {
        route: '/users/add-employee',
        element: <AddEmployee />,
        allowedRoles: ['admin'],
    },
    {
        route: '/users/edit-employee',
        element: <EditEmployee />,
        allowedRoles: ['admin'],
    },
    {
        route: '/users/editEmployee/:id',
        element: <EditEmployeePage />,
        allowedRoles: ['admin'],
    },
    {
        route: '/users/view-employee',
        element: <ViewEmployees />,
        allowedRoles: ['admin'],
    },
    {
        route: '/users/add-customer',
        element: <AddCustomer />,
        allowedRoles: ['admin', 'manager'],
    },
    {
        route: '/users/view-customer',
        element: <ViewCustomer />,
        allowedRoles: ['admin', 'manager','executive'],
    },
    {
        route: '/users/edit-customer/:id',
        element: <EditCustomer />,
        allowedRoles: ['admin', 'manager','executive'],
    },
    {
        route: '/add-product',
        element: <AddProduct />,
        allowedRoles: ['admin'],
    },

    {
        route: '/mediaLibrary',
        element: <MediaLibrary />,
        allowedRoles: ['admin'],
    },
    {
        route: '/mediaLibrary/*',
        element: <MediaLibrary />,
        allowedRoles: ['admin'],
    },
    {
        route: 'generalBulkUpload/*',
        element: <GeneralBulkUpload />,
        allowedRoles: ['admin'],
    },
    //complaints
    {
        route: '/complaint/add-complaint',
        element: <AddComplaint />,
        allowedRoles: ['admin', 'executive'],
    },
    {
        route: '/complaint/view-complaint',
        element: <ViewComplaint />,
        allowedRoles: ['admin', 'manager', 'executive'],
    },
    //purchaseOrder
    {
        route: '/purchaseOrder/add-purchaseOrder',
        element: <CreatePurchaseOrder />,
        allowedRoles: ['admin'],
    },
    {
        route: '/purchaseOrder/view-purchaseOrder/:poStatus',
        element: <ViewPurchaseOrder />,
        allowedRoles: ['admin', 'manager','executive'],
    },
    //Dispatch Location
    {
        route: '/dispatchLocation/add',
        element: <AddDispatchLocation />,
        allowedRoles: ['admin'],
    },
    {
        route: '/dispatchLocation/view',
        element: <ViewDispatchLocation />,
        allowedRoles: ['admin'],
    },
    {
        route: '/master/company',
        element: <Company />,
        allowedRoles: ['admin'],
    },
    {
        route: '/master/company/edit/:id',
        element: <EditCompany />,
        allowedRoles: ['admin'],
    },
];
