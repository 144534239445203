import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import PageWithCard from '../../components/infrastructure/PageWithCard';
import PrimaryButton from '../../components/infrastructure/Buttons/PrimaryButton';
import FormikInputGroup from '../../components/formik/FormikInputGroup';
import FormikTextareaGroup from '../../components/formik/FormikTextareaGroup';
import FormikSelectGroup from '../../components/formik/FormikSelectGroup';
import FormikPhoneInputGroup from '../../components/formik/FormikPhoneInputGroup';
import SecondaryButton from '../../components/infrastructure/Buttons/SecondaryButton';
import QueryString from 'qs';
import { authAxiosInstance } from '../../utils/axiosConfig';
import FormikAsyncSelect from '../../components/formik/FormikAsyncSelect';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomers } from '../../app/reducers/Customers/customerSlice';
import { toast } from 'react-toastify';
import DangerButton from '../../components/infrastructure/Buttons/DangerButton';
import {
    fetchDispatchLocations,
    getDispatchLocation,
} from '../../app/reducers/DispatchLocation/dispatchLocationSlice';
import { generateOptions } from '../../utils/Utils';
import {
    fetchCompanies,
    fetchCompany,
    getCompanies,
} from '../../app/reducers/Company/companySlice';
import { getAuth } from '../../app/reducers/Auth/authSlice';
import * as Yup from 'yup';
import { fetchEmployees, getEmployees } from '../../app/reducers/Users/EmployeeSlice';
import FormikMultiSelect from '../../components/formik/FormikMultiSelect';

const AddCustomer = () => {
    const { user } = useSelector(getAuth);
    const dispatch = useDispatch();

    const { employees, loading } = useSelector(getEmployees);
    const { dispatchLocation } = useSelector(getDispatchLocation);
    const { company } = useSelector(getCompanies);

    useEffect(() => {
        dispatch(fetchCompanies());
        dispatch(fetchDispatchLocations());
        dispatch(fetchEmployees({role : 'executive'}))
    }, []);

    const dispatchLocationData = useMemo(
        () => (dispatchLocation.docs ? dispatchLocation.docs : []),
        [dispatchLocation.docs]
    );

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            dispatchLocation: user?.location ?? '',
            companyName: '',
            location: '',
            address: '',
            phone: '91',
            machine: [],
            ink: [],
            company: '',
            executive: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required(),
            company: Yup.string().required(),
        }),
        onSubmit: async (values) => {
            // check for empty object
            const filteredMachineArray = values?.machine?.filter(
                (el) => Object.keys(el).length !== 0
            );
            const filteredInkArray = values?.ink?.filter(
                (el) => Object.keys(el).length !== 0
            );
            values.machine = filteredMachineArray;
            values.ink = filteredInkArray;

            await dispatch(createCustomers(values));
            formik.resetForm();
        },
    });
    return (
        <PageWithCard heading="Add Customer">
            <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-3"
            >
                <FormikInputGroup
                    formik={formik}
                    label="Name"
                    name="name"
                    required
                />
                {user.role == 'admin' ? (
                    <FormikSelectGroup
                        label="Dispatch Location"
                        name="dispatchLocation"
                        formik={formik}
                        options={generateOptions({
                            array: dispatchLocationData ?? [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                ) : (
                    <FormikSelectGroup
                        onChange={() => {}}
                        label="Dispatch Location"
                        name="dispatchLocation"
                        formik={formik}
                        options={generateOptions({
                            array:
                                dispatchLocationData.filter(
                                    (item) => item._id == user.location
                                ) ?? [],
                            labelField: 'name',
                            valueField: '_id',
                        })}
                    />
                )}
                <FormikInputGroup
                    formik={formik}
                    label="Location"
                    name="location"
                />
                {/* <FormikInputGroup
                    formik={formik}
                    label="Company Name"
                    name="companyName"
                /> */}
                <FormikSelectGroup
                    formik={formik}
                    name={'company'}
                    label="Company"
                    options={generateOptions({
                        array: company.docs ?? [],
                        labelField: 'name',
                        valueField: '_id',
                    })}
                    onChange={async (selectedOption) => {
                        formik.setFieldValue(`company`, selectedOption.value);
                        const resp = await dispatch(
                            fetchCompany({ _id: selectedOption.value })
                        );
                        console.log('resp',resp?.payload?.data?.docs)
                        if (resp?.payload?.data?.docs.length > 0) {
                            const { machine, ink, executive} =
                                resp?.payload?.data?.docs[0];
                            formik.setFieldValue(`machine`, machine);
                            formik.setFieldValue(`ink`, ink);
                            formik.setFieldValue(`executive`, executive);
                        } else {
                            formik.setFieldValue(`machine`, []);
                            formik.setFieldValue(`ink`, []);
                            formik.setFieldValue(`executive`, '');
                        }
                    }}
                    required
                />
                <FormikTextareaGroup
                    formik={formik}
                    label="Address"
                    name="address"
                />

                <FormikPhoneInputGroup
                    name="phone"
                    required
                    formik={formik}
                    label="Phone"
                />
                {/* machine */}
                {formik.values.name && (
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="machine"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2 border p-2 rounded-md">
                                        {formik?.values?.machine?.length >
                                            0 && (
                                            <label className="text text-base font-semibold">
                                                Machine
                                            </label>
                                        )}
                                        <div>
                                            {formik?.values?.machine?.length > 0
                                                ? formik?.values?.machine?.map(
                                                      (ele, index) => (
                                                          <div
                                                              className="relative p-4 mb-2"
                                                              style={{
                                                                  border: '1px solid #d6c7c7',
                                                                  borderRadius:
                                                                      '5px',
                                                              }}
                                                              key={index}
                                                          >
                                                              <FormikAsyncSelect
                                                                  label="Search Machine"
                                                                  formik={
                                                                      formik
                                                                  }
                                                                  name={`machine.${index}`}
                                                                  getOptions={async (
                                                                      value
                                                                  ) => {
                                                                      try {
                                                                          const string =
                                                                              QueryString.stringify(
                                                                                  {
                                                                                      search: value,
                                                                                      type: {
                                                                                          $ne: 'INK',
                                                                                      },
                                                                                  }
                                                                              );

                                                                          const productsResp =
                                                                              await authAxiosInstance.get(
                                                                                  `/products?${string}`
                                                                              );
                                                                          const options =
                                                                              productsResp?.data?.data?.docs?.map(
                                                                                  (
                                                                                      ele
                                                                                  ) => ({
                                                                                      label: ele?.name,
                                                                                      value: ele?._id,
                                                                                  })
                                                                              );
                                                                          return options;
                                                                      } catch (error) {
                                                                          console.log(
                                                                              error
                                                                          );
                                                                      }
                                                                  }}
                                                                  onChange={(
                                                                      selectedValue
                                                                  ) => {
                                                                      //check whether same id is used or not
                                                                      const isSameMachineExist =
                                                                          formik?.values?.machine?.find(
                                                                              (
                                                                                  el
                                                                              ) =>
                                                                                  el ===
                                                                                  selectedValue.value
                                                                          );
                                                                      if (
                                                                          isSameMachineExist
                                                                      ) {
                                                                          arrayHelpers.remove(
                                                                              index
                                                                          );
                                                                          return toast.error(
                                                                              'Already Selected above'
                                                                          );
                                                                      }
                                                                      //machineId
                                                                      formik.setFieldValue(
                                                                          `machine.${index}`,
                                                                          selectedValue.value
                                                                      );
                                                                  }}
                                                                  isDisabled
                                                              />
                                                              {/* <div className="mt-6 cursor-pointer">
                                                            <DangerButton
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div> */}
                                                          </div>
                                                      )
                                                  )
                                                : 'No machine Found'}
                                        </div>
                                        {/* <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div> */}
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                )}

                {/* ink */}
                {formik.values.name && (
                    <FormikProvider value={formik}>
                        <FieldArray
                            name="ink"
                            render={(arrayHelpers) => {
                                return (
                                    <div className="flex flex-col gap-2 border p-2 rounded-md">
                                        {formik?.values?.ink?.length > 0 && (
                                            <label className="text text-base font-semibold ">
                                                Ink
                                            </label>
                                        )}
                                        <div>
                                            {formik?.values?.ink?.length > 0
                                                ? formik?.values?.ink?.map(
                                                      (ele, index) => (
                                                          <div
                                                              className="relative p-4 mb-2"
                                                              style={{
                                                                  border: '1px solid #d6c7c7',
                                                                  borderRadius:
                                                                      '5px',
                                                              }}
                                                              key={index}
                                                          >
                                                              <FormikAsyncSelect
                                                                  label="Search Ink"
                                                                  formik={
                                                                      formik
                                                                  }
                                                                  name={`ink.${index}`}
                                                                  getOptions={async (
                                                                      value
                                                                  ) => {
                                                                      try {
                                                                          const string =
                                                                              QueryString.stringify(
                                                                                  {
                                                                                      search: value,
                                                                                      type: {
                                                                                          $ne: 'MACHINE',
                                                                                      },
                                                                                  }
                                                                              );

                                                                          const productsResp =
                                                                              await authAxiosInstance.get(
                                                                                  `/products?${string}`
                                                                              );
                                                                          const options =
                                                                              productsResp?.data?.data?.docs?.map(
                                                                                  (
                                                                                      ele
                                                                                  ) => ({
                                                                                      label: ele?.name,
                                                                                      value: ele?._id,
                                                                                  })
                                                                              );
                                                                          return options;
                                                                      } catch (error) {
                                                                          console.log(
                                                                              error
                                                                          );
                                                                      }
                                                                  }}
                                                                  onChange={(
                                                                      selectedValue
                                                                  ) => {
                                                                      //check whether same id is used or not
                                                                      const isSameInkExist =
                                                                          formik?.values?.ink?.find(
                                                                              (
                                                                                  el
                                                                              ) =>
                                                                                  el ===
                                                                                  selectedValue.value
                                                                          );
                                                                      if (
                                                                          isSameInkExist
                                                                      ) {
                                                                          arrayHelpers.remove(
                                                                              index
                                                                          );
                                                                          return toast.error(
                                                                              'Already Selected above'
                                                                          );
                                                                      }
                                                                      //Ink Id
                                                                      formik.setFieldValue(
                                                                          `ink.${index}`,
                                                                          selectedValue.value
                                                                      );
                                                                  }}
                                                                  isDisabled
                                                              />
                                                              {/* <div className="mt-6 cursor-pointer">
                                                            <DangerButton
                                                                type="button"
                                                                onClick={() =>
                                                                    arrayHelpers.remove(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </DangerButton>
                                                        </div> */}
                                                          </div>
                                                      )
                                                  )
                                                : 'No Ink Found'}
                                        </div>
                                        {/* <div>
                                            <SecondaryButton
                                                onClick={() => {
                                                    arrayHelpers.push({});
                                                }}
                                                type="button"
                                            >
                                                Add More
                                            </SecondaryButton>
                                        </div> */}
                                    </div>
                                );
                            }}
                        />
                    </FormikProvider>
                )}

                {formik.values.name && (
                    formik.values.executive ?
                    <FormikMultiSelect
                        formik={formik}
                        name="executive"
                        label="Executive"
                        options={ employees?.docs ?
                            employees?.docs?.map((ele)=>({
                                label : `${ele.firstName} ${ele.lastName}`,
                                value : ele._id
                            })) : []
                        }
                        isDisabled
                    />
                    : <div className='border p-2 rounded-md'>
                    No Executive Found
                </div>
                )}

                <div>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                </div>
            </form>
        </PageWithCard>
    );
};

export default AddCustomer;
