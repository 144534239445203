import React from 'react';
import { Briefcase, Edit, Mail, Monitor, User, UserPlus } from 'react-feather';
import SidebarGroup from '../../components/infrastructure/SidebarGroup';
import SidebarSingle from '../../components/infrastructure/SidebarSingle';
import SidebarTopText from '../../components/infrastructure/SidebarTopText';
import { LINKS } from '../../utils/links';

const SidebarLinks = ({ sidebarOpen, sidebarExpanded, setSidebarExpanded }) => {
    return (
        <ul className="mt-3">
            {/* Dashboard */}
            <SidebarTopText text="Application" sidebarOpen={sidebarOpen} />
            
            {LINKS.map((item) =>
                item.type == 'single' ? (
                    <SidebarSingle
                        allowedRoles={item?.allowedRoles}
                        activePath={item?.link}
                        name={item?.label}
                        Icon={item?.Icon}
                        sidebarOpen={sidebarOpen}
                    />
                ) : (
                    <SidebarGroup
                        links={item?.links}
                        heading={item?.heading}
                        Icon={item?.Icon}
                        setSidebarExpanded={setSidebarExpanded}
                        sidebarExpanded={sidebarExpanded}
                    />
                )
            )}
        </ul>
    );
};

export default SidebarLinks;
