import {
    Archive,
    Briefcase,
    Monitor,
    Package,
    Twitch,
    User,
} from 'react-feather';

export const LINKS = [
    {
        type: 'single',
        link: '/',
        label: 'Dashboard',
        Icon: <Monitor />,
        allowedRoles: ['admin', 'manager','executive'],
    },
    {
        links: [
            {
                label: 'Add Employee',
                link: '/users/add-employee',
                allowedRoles: ['admin'],
            },
            {
                label: 'View Employee',
                link: '/users/view-employee',
                allowedRoles: ['admin'],
            },
            {
                label: 'Edit Employee',
                link: '/users/edit-employee',
                allowedRoles: ['admin'],
            },
            {
                label: 'Add Customer',
                link: '/users/add-customer',
                allowedRoles: ['admin', 'manager'],
            },
            {
                label: 'View Customer',
                link: '/users/view-customer',
                allowedRoles: ['admin', 'manager','executive'],
            },
        ],
        heading: 'Users',
        Icon: <User />,
        type: 'group',
    },
    {
        links: [
            {
                label: 'Company',
                link: '/master/company',
                allowedRoles: ['admin'],
            },
        ],
        heading: 'Master',
        Icon: <User />,
        type: 'group',
    },

    //complaints
    {
        links: [
            {
                label: 'Add Complaint',
                link: '/complaint/add-complaint',
                allowedRoles: ['admin','executive'],
            },
            {
                label: 'View Complaint',
                link: '/complaint/view-complaint',
                allowedRoles: ['admin', 'manager','executive'],
            },
        ],
        heading: 'Complaints',
        Icon: <Twitch />,
        type: 'group',
    },

    //purchaseOrder

    {
        links: [
            {
                label: 'Create PurchaseOrder',
                link: '/purchaseOrder/add-purchaseOrder',
                allowedRoles: ['admin'],
            },
            {
                label: 'View PurchaseOrder',
                link: '/purchaseOrder/view-purchaseOrder/open',
                allowedRoles: ['admin', 'manager','executive'],
            },
        ],
        heading: 'Purchase Order',
        Icon: <Package />,
        type: 'group',
    },

    //product
    {
        type: 'single',
        link: '/add-product',
        label: 'Products',
        Icon: <Archive />,
        allowedRoles: ['admin'],
    },

    //Dispatch Location
    {
        links: [
            {
                label: 'Add Dispatch Location',
                link: '/dispatchLocation/add',
                allowedRoles: ['admin'],
            },
            {
                label: 'View Dispatch Location',
                link: '/dispatchLocation/view',
                allowedRoles: ['admin'],
            },
        ],
        heading: 'Dispatch Location',
        Icon: <User />,
        type: 'group',
    },
    {
        type: 'single',
        link: '/generalBulkUpload',
        label: 'General Bulk Upload',
        Icon: <Briefcase />,
        allowedRoles: ['admin'],
    },
    {
        type: 'single',
        link: '/mediaLibrary',
        label: 'Media Library',
        Icon: <Briefcase />,
        allowedRoles: ['admin', 'sub_admin'],
    },
];
